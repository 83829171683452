var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h2", { staticClass: "title" }, [_vm._v("Aktuelle Releases")]),
    _c(
      "div",
      { staticClass: "row" },
      _vm._l(_vm.days, function(day, index) {
        return _c("div", { staticClass: "col sm" }, [
          _c(
            "a",
            {
              attrs: { href: "#x" },
              on: {
                click: function($event) {
                  return _vm.switchDay(day.offset)
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "card text-white text-center daycard",
                  class: [
                    day.offset === _vm.currentOffset
                      ? "bg-primary"
                      : "bg-secondary"
                  ]
                },
                [
                  _c("div", { staticClass: "card-body p-2" }, [
                    _c("h5", { staticClass: "card-title" }, [
                      _vm._v(" " + _vm._s(day.d))
                    ]),
                    _c("small", [_vm._v(_vm._s(day.ts))])
                  ])
                ]
              )
            ]
          )
        ])
      }),
      0
    ),
    _vm.allReleases && _vm.allReleases.length > 0
      ? _c(
          "div",
          [
            _c("hr"),
            _c("small", { staticClass: "text-muted" }, [_vm._v("Sortierung:")]),
            _vm._v(" "),
            _c(
              "a",
              {
                class: { active: _vm.sortAlpha },
                attrs: { href: "#x" },
                on: {
                  click: function($event) {
                    return _vm.toggleSort("alpha")
                  }
                }
              },
              [_vm._v("Alphabetisch")]
            ),
            _vm._v(" | "),
            _c(
              "a",
              {
                class: { active: _vm.sortDate },
                attrs: { href: "#x" },
                on: {
                  click: function($event) {
                    return _vm.toggleSort("date")
                  }
                }
              },
              [_vm._v("Nach Veröffentlichung")]
            ),
            _c("br"),
            _c("small", { staticClass: "text-muted" }, [_vm._v("Auflösung:")]),
            _vm._v(" "),
            _vm._l(_vm.filters.resolutions, function(resolution, index) {
              return _c("span", [
                _c(
                  "a",
                  {
                    class: { active: resolution === _vm.resolutionFilter },
                    attrs: { href: "#x" },
                    on: {
                      click: function($event) {
                        return _vm.toggleResolutionFilter(resolution)
                      }
                    }
                  },
                  [_vm._v(_vm._s(resolution))]
                ),
                index != Object.keys(_vm.filters.resolutions).length - 1
                  ? _c("span", [_vm._v(" | ")])
                  : _vm._e()
              ])
            }),
            _c("br"),
            _c("small", { staticClass: "text-muted" }, [_vm._v("Sprache:")]),
            _vm._v(" "),
            _c(
              "a",
              {
                class: { active: _vm.languageFilter === "german" },
                attrs: { href: "#x" },
                on: {
                  click: function($event) {
                    return _vm.toggleLanguageFilter("german")
                  }
                }
              },
              [_vm._v("Deutsch")]
            ),
            _vm._v(" | "),
            _c(
              "a",
              {
                class: { active: _vm.languageFilter === "other" },
                attrs: { href: "#x" },
                on: {
                  click: function($event) {
                    return _vm.toggleLanguageFilter("other")
                  }
                }
              },
              [_vm._v("Fremdsprachig/Mehrsprachig")]
            ),
            _c("br"),
            _vm.languageFilter || _vm.resolutionFilter
              ? _c("div", [
                  _c("hr"),
                  _c(
                    "a",
                    { attrs: { href: "#x" }, on: { click: _vm.resetFilters } },
                    [_vm._v("Filter zurücksetzen")]
                  )
                ])
              : _vm._e(),
            _c("hr"),
            _c(
              "table",
              { staticClass: "table lastestreleases" },
              _vm._l(_vm.releases, function(release, index) {
                return _c("tr", { key: index, staticClass: "small" }, [
                  _c("td", [
                    _c("span", { staticClass: "text-muted" }, [
                      _vm._v(_vm._s(_vm._f("time")(release.createdAt)))
                    ]),
                    _vm._v("  ")
                  ]),
                  _c("td", [
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            "/serie/" + release._media.slug + "/" + release.name
                        }
                      },
                      [_vm._v(_vm._s(release.name))]
                    ),
                    _vm._v(" "),
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$options.filters.flag(release.language)
                        )
                      }
                    })
                  ])
                ])
              }),
              0
            ),
            _vm.seasonpacks && _vm.seasonpacks.length
              ? _c("div", [
                  _c("h3", { staticClass: "title" }, [
                    _vm._v("Neue ganze Staffeln")
                  ]),
                  _c(
                    "table",
                    { staticClass: "table lastestreleases" },
                    _vm._l(_vm.seasonpacks, function(release, index) {
                      return _c("tr", { key: index, staticClass: "small" }, [
                        _c("td", [
                          _c("span", { staticClass: "text-muted" }, [
                            _vm._v(_vm._s(_vm._f("time")(release.createdAt)))
                          ]),
                          _vm._v("  ")
                        ]),
                        _c("td", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "/serie/" +
                                  release._media.slug +
                                  "/" +
                                  release.name
                              }
                            },
                            [_vm._v(_vm._s(release.name))]
                          ),
                          _vm._v(" "),
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$options.filters.flag(release.language)
                              )
                            }
                          })
                        ])
                      ])
                    }),
                    0
                  )
                ])
              : _vm._e()
          ],
          2
        )
      : _c("div", [
          _c("hr"),
          _c("div", { staticClass: "alert alert-secondary" }, [
            _vm._v(
              "An diesem Tag wurden (noch) keine neuen Release gefunden!       "
            )
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }