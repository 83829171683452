<template lang="pug">
div
    h2.title Aktuelle Releases

    .row
      .col.sm(v-for="(day, index) in days")
        a(href='#x' @click="switchDay(day.offset)")
          .card.text-white.text-center.daycard(:class="[day.offset === currentOffset ? 'bg-primary' : 'bg-secondary']")
            .card-body.p-2
              h5.card-title 
                | {{day.d}}
              small
                | {{day.ts}}

    div(v-if="allReleases && allReleases.length > 0")
      hr
      | <small class="text-muted">Sortierung:</small> <a href="#x" :class="{active: sortAlpha}" @click="toggleSort('alpha')">Alphabetisch</a> | <a href="#x" :class="{active: sortDate}" @click="toggleSort('date')">Nach Veröffentlichung</a>
      br
      | <small class="text-muted">Auflösung:</small> 
      span(v-for='resolution, index in filters.resolutions')
        | <a  href="#x" :class="{active: resolution === resolutionFilter}" @click="toggleResolutionFilter(resolution)">{{resolution}}</a><span v-if="index != Object.keys(filters.resolutions).length - 1"> | </span>
      br
      | <small class="text-muted">Sprache:</small> <a href="#x" :class="{active: languageFilter === 'german'}" @click="toggleLanguageFilter('german')">Deutsch</a> | <a href="#x" :class="{active: languageFilter === 'other'}" @click="toggleLanguageFilter('other')">Fremdsprachig/Mehrsprachig</a>
      br
      div(v-if='languageFilter || resolutionFilter')
        hr
        a(href='#x' @click="resetFilters")
          | Filter zurücksetzen
      hr
      table.table.lastestreleases
        tr.small(v-for='release, index in releases' :key="index")
          td <span class="text-muted">{{release.createdAt | time}}</span>&nbsp;&nbsp;
          td
            <a :href="`/serie/${release._media.slug}/${release.name}`">{{release.name}}</a> <span v-html="$options.filters.flag(release.language)"></span>

      div(v-if="seasonpacks && seasonpacks.length")
        h3.title Neue ganze Staffeln
        table.table.lastestreleases
          tr.small(v-for='release, index in seasonpacks' :key="index")
            td <span class="text-muted">{{release.createdAt | time}}</span>&nbsp;&nbsp;
            td
              <a :href="`/serie/${release._media.slug}/${release.name}`">{{release.name}}</a> <span v-html="$options.filters.flag(release.language)"></span>  

    div(v-else)
      hr
      .alert.alert-secondary
        | An diesem Tag wurden (noch) keine neuen Release gefunden!       


</template>

<script>
import api from "../../../../shared/api";
import moment from "moment";

moment.locale("de");

const knownResolutions = ["SD", "720p", "1080p", "2160p"];
const hdResolutions = ["720p", "1080p", "2160p"];

export default {
  name: "LatestReleaseList",
  data() {
    return {
      currentOffset: 0,
      sortAlpha: false,
      sortDate: true,
      resolutionFilter: false,
      languageFilter: false,
      filters: {
        resolutions: [],
        encodings: [],
      },
      sortChanged: false,
      releases: {},
      seasonpacks: {},
      allReleases: {},
    };
  },
  methods: {
    toggleSort(sorter) {
      if (this.sortAlpha && sorter == "date") {
        this.sortDate = true;
        this.sortAlpha = false;
        this.filterSortReleases();
      } else if (this.sortDate && sorter == "alpha") {
        this.sortDate = false;
        this.sortAlpha = true;
        this.filterSortReleases();
      }
    },
    resetFilters() {
      this.languageFilter = false;
      this.resolutionFilter = false;
      this.filterSortReleases();
    },
    toggleResolutionFilter(resolution) {
      this.languageFilter = false;
      this.resolutionFilter = resolution;
      this.filterSortReleases();
    },
    toggleLanguageFilter(language) {
      this.resolutionFilter = false;
      this.languageFilter = language;
      this.filterSortReleases();
    },
    getFilters() {
      const resolutions = [];
      for (let release of this.allReleases) {
        if (release.resolution && !resolutions.includes(release.resolution)) {
          resolutions.push(release.resolution);
        }
      }
      this.filters.resolutions = [];
      knownResolutions.forEach((resolution) => {
        if (resolutions.includes(resolution)) {
          this.filters.resolutions.push(resolution);
        }
      });

      if (!this.filters.resolutions.includes(this.resolutionFilter)) {
        this.resolutionFilter = false;
      }
    },
    filterSortReleases() {
      this.releases = {};

      if (this.resolutionFilter) {
        if (this.resolutionFilter !== "SD") {
          this.releases = this.allReleases.filter((release) => {
            return (
              release.resolution.trim().toLowerCase() === this.resolutionFilter
            );
          });
        } else {
          this.releases = this.allReleases.filter((release) => {
            const resolution = release.resolution.trim().toLowerCase();
            return !hdResolutions.find((hdres) => resolution.includes(hdres));
          });
        }
      } else if (this.languageFilter) {
        this.releases = this.allReleases.filter((release) => {
          if (this.languageFilter === "german") {
            return release.language.trim().toLowerCase() === "german";
          } else {
            return release.language.trim().toLowerCase() !== "german";
          }
        });
      } else {
        this.releases = this.allReleases;
      }

      if (this.sortAlpha) {
        this.releases = this.releases.sort((a, b) => {
          return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
        });
      } else {
        this.releases = this.releases.sort((a, b) => {
          return a.createdAt > b.createdAt ? -1 : 1;
        });
      }

      this.seasonpacks = this.releases.filter(release => !release.episode);
      this.releases = this.releases.filter(release => release.episode);
    },
    async switchDay(offset) {
      this.allReleases = await api.getLatestReleases(offset);
      this.getFilters();
      this.filterSortReleases();
      this.currentOffset = offset;
    },
  },
  filters: {
    timesince: function(date) {
      return moment(date).fromNow();
    },
    time: function(date) {
      return moment(date).format("HH:mm");
    },
    flag: function(language) {
      if (!language) return "";
      const flags = [];
      if (
        language
          .trim()
          .toLowerCase()
          .includes("german")
      ) {
        flags.push('<img class="flag" src="/img/de.png" alt="Deutsch">');
      }
      if (
        language
          .trim()
          .toLowerCase()
          .includes("english")
      ) {
        flags.push('<img class="flag" src="/img/eng.png" alt="Englisch">');
      }
      return flags.join(" ");
    },
  },

  computed: {
    days: function() {
      const days = [];
      for (let i = 4; i >= 0; i--) {
        const d = moment().subtract(i, "days");
        days.push({
          ts: d.format("DD.MM."),
          d: d.format("dd"),
          offset: i,
        });
      }
      return days;
    },
  },

  async created() {
    await this.switchDay(this.currentOffset);

    // this.getFilters();
  },
};

/*

*/
</script>

<style scoped>
.active {
  color: #f65f24;
  font-weight: bold;
}

.daycard:hover {
  background-color: #333333 !important;
}

a:hover {
  text-decoration: none !important;
}

.bg-primary {
  background-color: #f65f24 !important;
}

.bg-primary:hover {
  background-color: #f65f24 !important;
}
</style>
