// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';

import LatestReleaseList from './components/LatestReleaseList';


Vue.config.productionTip = false;
Vue.config.devtools = true;
Vue.use(BootstrapVue);

/* eslint-disable no-new */
new Vue({
  el: '#v-latest-releases-list',
  components: { LatestReleaseList },
  template: '<LatestReleaseList/>',
});
